<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Property Admin</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.PropertyAdminDialog.open(property)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Property's Admin</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Accom Viewings</th>
                  <td>{{ property.accom_viewings ? "Yes" : "No" }}</td>
                </tr>
                <tr>
                  <th>Caseload Ref</th>
                  <td>{{ property.caseload_reference }}</td>
                </tr>
                <tr>
                  <th>Key Info</th>
                  <td>{{ property.key_info }}</td>
                </tr>
                <tr>
                  <th>Managing Office</th>
                  <td>{{ property.managing_office }}</td>
                </tr>
                <tr>
                  <th>Property Manager</th>
                  <td>
                    <div v-if="property.user_menager">
                      {{ property.user_menager.full_name }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>For Sale Board</th>
                  <td>{{ property.for_sale_board }}</td>
                </tr>
                <tr>
                  <th>Featured Property</th>
                  <td>
                    {{ property.featured_property ? "Yes" : "No" }}
                  </td>
                </tr>
                <tr>
                  <th>Published</th>
                  <td>
                    {{ property.visible ? "Yes" : "No" }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Notes</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.notesDialog.open(property)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Notes</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <td>{{ property.notes }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <property-admin-dialog ref="PropertyAdminDialog" />
    <notes-dialog ref="notesDialog" />
  </div>
</template>

<script>
import PropertyAdminDialog from "./components/PropertyAdminDialog.vue";
import NotesDialog from "./components/NotesDialog.vue";

export default {
  components: {
    PropertyAdminDialog,
    NotesDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          to: { name: "module-melrose-properties-all" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    property() {
      return this.$store.getters["melrose/propertiesStore/get"];
    },
  },

  methods: {
    downloadPdf() {},
  },

  created() {
    this.breadcrumbs.push({
      text: this.property.address.short,
      disabled: true,
    });
  },
};
</script>
